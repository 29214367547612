import { api } from "@/utils/api";
import { resolveQueryParams } from "@/utils/helper";

export default {
    namespaced: true,

    state: {
        listService: [],
    },
    getters: {
        listService(state) {
            return state.listService
        }
    },
    mutations: {
        SET_LIST_SERVICE(state, listService) {
            state.listService = listService
        }
    },
    actions: {
        async getListService({ commit }, query) {
            try {
                await api.get(`penawaran?${resolveQueryParams(query)}`, {
                    headers: {
                        "Content-Type": "application/json",
                    }
                })
                    .then((response) => {
                        commit('SET_LIST_SERVICE', response.data.data)
                    })
                    .catch(() => {
                        commit('SET_LIST_SERVICE', [])
                    })
            } catch (e) {
                commit('SET_LIST_SERVICE', [])
            }
        },
        getSingleService(_, query) {
            return new Promise((resolve, reject) => {
                api.get(`penawaran?${resolveQueryParams(query)}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        postService(_, data) {
            return new Promise((resolve, reject) => {
                api.post('penawaran', data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        postReqBarang(_, data) {
            return new Promise((resolve, reject) => {
                api.post('reqbarang', data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        postReqFixedCost(_, data) {
            return new Promise((resolve, reject) => {
                api.post('reqfixcost', data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        putService(_, { idPenawaran, data }) {
            return new Promise((resolve, reject) => {
                api.put(`penawaran/${idPenawaran}`, data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        putReqBarang(_, { idReqBarang, data }) {
            return new Promise((resolve, reject) => {
                api.put(`reqbarang/${idReqBarang}`, data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        deleteBarangService(_, id) {
            return new Promise((resolve, reject) => {
                api.delete(`penawaran/barang_service/${id}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        deletePengerjaanService(_, id) {
            return new Promise((resolve, reject) => {
                api.delete(`penawaran/pengerjaan_service/${id}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        deleteSparepartService(_, id) {
            return new Promise((resolve, reject) => {
                api.delete(`penawaran/sparepart_service/${id}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        deleteReqBarang(_, idReqBarang) {
            return new Promise((resolve, reject) => {
                api.delete(`reqbarang/${idReqBarang}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        deleteReqFixedCost(_, idReqFixedCost) {
            return new Promise((resolve, reject) => {
                api.delete(`reqfixcost/${idReqFixedCost}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}