export const resolveLevel = (value) => {
    if (value === 'M') {
        return "MASTER"
    } else if (value === 'A') {
        return "ADMIN"
    } else if (value === 'G') {
        return "GUDANG"
    } else if (value === 'B') {
        return "BENGKEL"
    } else if (value === 'S') {
        return "SALES"
    } else if (value === 'C') {
        return "CS"
    }
}

export const isUndefined = (s) => typeof s === "undefined";

export const bulan = (val) => {
    switch (val) {
        case "01":
            return "Januari";
        case "02":
            return "Pebruari";
        case "03":
            return "Maret";
        case "04":
            return "April";
        case "05":
            return "Mei";
        case "06":
            return "Juni";
        case "07":
            return "Juli";
        case "08":
            return "Agustus";
        case "09":
            return "September";
        case "10":
            return "Oktober";
        case "11":
            return "Nopember";
        case "12":
            return "Desember";
    }
}

export const resolveQueryParams = (params) => {
    if (typeof params !== "object") return "";

    const payload = Object.entries(params).reduce((result, [key, value]) => {
        if (!isUndefined(params)) result[key] = value;
        return result;
    }, {});
    return new URLSearchParams(payload).toString();
}

export const formatDateInd = (val) => {
    let date = val.substr(0, 10)
    let dt = date.split('-')
    let year = dt[0]
    let month = bulan(dt[1])
    let day = dt[2]

    return `${day} ${month} ${year}`;
}

export const formatDateSimple = (val) => {
    let date = val.substr(0, 10)
    let dt = date.split('-')
    let year = dt[0]
    let month = dt[1]
    let day = dt[2]

    return `${day}/${month}/${year}`
}

export const dateRangeValue = () => {
    let dateRange = [
        new Date(
            new Date().setMonth(new Date().getMonth() - 3) -
            new Date().getTimezoneOffset() * 60000
        )
            .toISOString()
            .substring(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substring(0, 10),
    ]
    return dateRange
}

export const startDateValue = () => {
    let start = new Date(
        new Date().setMonth(new Date().getMonth() - 3) -
        new Date().getTimezoneOffset() * 60000
    ).toISOString().substring(0, 10)
    return start
}

export const endDateValue = () => {
    let end = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10)
    return end
}

export const maxDateToday = () => {
    let maxDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10)
    return maxDate
}

export const moneyFormat = (val) => {
    if (val) {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return val.toFixed(2);
    }
}

export const terbilang = (val) => {
    var bilne = [
        "",
        "satu",
        "dua",
        "tiga",
        "empat",
        "lima",
        "enam",
        "tujuh",
        "delapan",
        "sembilan",
        "sepuluh",
        "sebelas",
    ];
    if (val < 12) {
        return bilne[val];
    } else if (val < 20) {
        return terbilang(val - 10) + " belas";
    } else if (val < 100) {
        return (
            terbilang(Math.floor(parseInt(val) / 10)) +
            " puluh " +
            terbilang(parseInt(val) % 10)
        );
    } else if (val < 200) {
        return "seratus " + terbilang(parseInt(val) - 100);
    } else if (val < 1000) {
        return (
            terbilang(Math.floor(parseInt(val) / 100)) +
            " ratus " +
            terbilang(parseInt(val) % 100)
        );
    } else if (val < 2000) {
        return "seribu " + terbilang(parseInt(val) - 1000);
    } else if (val < 1000000) {
        return (
            terbilang(Math.floor(parseInt(val) / 1000)) +
            " ribu " +
            terbilang(parseInt(val) % 1000)
        );
    } else if (val < 1000000000) {
        return (
            terbilang(Math.floor(parseInt(val) / 1000000)) +
            " juta " +
            terbilang(parseInt(val) % 1000000)
        );
    } else if (val < 1000000000000) {
        return (
            terbilang(Math.floor(parseInt(val) / 1000000000)) +
            " milyar " +
            terbilang(parseInt(val) % 1000000000)
        );
    } else if (val < 1000000000000000) {
        return (
            terbilang(Math.floor(parseInt(val) / 1000000000000)) +
            " trilyun " +
            terbilang(parseInt(val) % 1000000000000)
        );
    }
}

export const IsNumber = (event) => {
    if (!/\d/.test(event.key) && event.key !== '.') return event.preventDefault();
}

export const totalPengerjaanService = (data) => {
    let total = 0
    if (data?.barang_service?.length > 0) {
        data?.barang_service.map((el) => {
            if (el.pengerjaan_service.length > 0) {
                el.pengerjaan_service.map((e) => {
                    total += Number(e.qty_pengerjaan) * Number(e.harga_pengerjaan);
                });
            }
        });
    }
    return total
}

export const totalSparepartService = (data) => {
    let total = 0;
    if (data?.sparepart_service?.length > 0) {
        data?.sparepart_service?.map((el) => {
            total += Number(el.qty_barang) * Number(el.harga_barang);
        });
    }
    return total;
}

export const totalLogistikService = (data) => {
    let total = 0;
    if (data?.req_barang?.length > 0) {
        data?.req_barang?.map((el) => {
            total += Number(el.terima_qty) * Number(el.harga_modal);
        });
    }
    return total;
}

export const totalFixedCostService = (data) => {
    let total = 0;
    if (data?.req_fix_cost?.length > 0) {
        data?.req_fix_cost?.map((el) => {
            total += Number(el.harga);
        });
    }
    return total;
}

export const ppnService = (data, ppn) => {
    let nominalPpn = 0;
    if (data?.pajak) {
        let grandTotal = totalPengerjaanService(data) + totalSparepartService(data) - data.diskon
        nominalPpn = grandTotal * (ppn / 100)
    }
    return nominalPpn
}

export const pphService = (data, pph) => {
    let nominalPph = 0
    if (data?.barang_service?.length > 0) {
        let total = 0
        data?.barang_service?.map((el) => {
            if (el.pengerjaan_service.length > 0) {
                el.pengerjaan_service.map((e) => {
                    total += Number(e.qty_pengerjaan) * Number(e.harga_pengerjaan);
                });
            }
        })
        nominalPph = total * (pph / 100)
    }
    return nominalPph
}