import { api } from "@/utils/api"

export default {
    namespaced: true,

    state: {
        companyProfile: {},
    },
    getters: {
        companyProfile(state) {
            return state.companyProfile
        }
    },
    mutations: {
        SET_COMPANY_PROFILE(state, companyProfile) {
            state.companyProfile = companyProfile
        }
    },
    actions: {
        async getCompany({ commit }) {
            try {
                await api.get('cp')
                    .then((response) => {
                        commit('SET_COMPANY_PROFILE', response.data.data[0])
                    })
                    .catch(() => {
                        commit('SET_COMPANY_PROFILE', [])
                    })
            } catch (e) {
                commit('SET_COMPANY_PROFILE', [])
            }
        },

        async putCompany(_, data) {
            await api.put(`cp/${data.uid}`, data)
        }
    }
}