import { api } from "@/utils/api"

export default {
    namespaced: true,

    state: {
        customer: [],
    },
    getters: {
        customer(state) {
            return state.customer
        }
    },
    mutations: {
        SET_CUSTOMER(state, customer) {
            state.customer = customer
        },
    },
    actions: {
        async getCustomer({ commit }) {
            try {
                await api.get('customers')
                    .then((response) => {
                        commit('SET_CUSTOMER', response.data.data)
                    })
                    .catch(() => {
                        commit('SET_CUSTOMER', [])
                    })
            } catch (e) {
                commit('SET_CUSTOMER', [])
            }
        },

        getCustomerById(_, idCustomer) {
            return new Promise((resolve, reject) => {
                api.get(`customers/${idCustomer}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        postCustomer(_, data) {
            return new Promise((resolve, reject) => {
                api.post('customers', data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        putCustomer(_, data) {
            return new Promise((resolve, reject) => {
                api.put(`customers/${data.uid}`, data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}