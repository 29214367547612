import { api } from "@/utils/api"

export default {
    namespaced: true,

    state: {
        supplier: [],
    },
    getters: {
        supplier(state) {
            return state.supplier
        }
    },
    mutations: {
        SET_SUPPLIER(state, supplier) {
            state.supplier = supplier
        },
    },
    actions: {
        async getSupplier({ commit }) {
            try {
                await api.get('suppliers')
                    .then((response) => {
                        commit('SET_SUPPLIER', response.data.data)
                    })
                    .catch(() => {
                        commit('SET_SUPPLIER', [])
                    })
            } catch (e) {
                commit('SET_SUPPLIER', [])
            }
        },

        getSupplierById(_, idSupplier) {
            return new Promise((resolve, reject) => {
                api.get(`suppliers/${idSupplier}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        postSupplier(_, data) {
            return new Promise((resolve, reject) => {
                api.post('suppliers', data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        putSupplier(_, data) {
            return new Promise((resolve, reject) => {
                api.put(`suppliers/${data.uid}`, data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}