import { api } from "@/utils/api"

export default {
    namespaced: true,

    state: {
        user: [],
        userById: {},
    },
    getters: {
        user(state) {
            return state.user
        },
        userById(state) {
            return state.userById
        }
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user
        },
        SET_USER_BY_ID(state, userById) {
            state.userById = userById
        }
    },
    actions: {
        async getUser({ commit }) {
            try {
                await api.get('users')
                    .then((response) => {
                        commit('SET_USER', response.data.data)
                    })
                    .catch(() => {
                        commit('SET_USER', [])
                    })
            } catch (e) {
                commit('SET_USER', [])
            }
        },

        getUserById({ commit }, idUser) {
            return new Promise((resolve, reject) => {
                api.get(`users/${idUser}`)
                    .then((response) => {
                        commit('SET_USER_BY_ID', response.data)
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        postUser(_, data) {
            return new Promise((resolve, reject) => {
                api.post('users', data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        putUser(_, data) {
            return new Promise((resolve, reject) => {
                api.put(`users/${data.uid}`, data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}