import { api } from "@/utils/api"

export default {
    namespaced: true,

    state: {
        montir: [],
    },
    getters: {
        montir(state) {
            return state.montir
        }
    },
    mutations: {
        SET_MONTIR(state, montir) {
            state.montir = montir
        }
    },
    actions: {
        async getMontir({ commit }) {
            try {
                await api.get('montir')
                    .then((response) => {
                        commit('SET_MONTIR', response.data.data)
                    })
                    .catch(() => {
                        commit('SET_MONTIR', [])
                    })
            } catch (e) {
                commit('SET_MONTIR', [])
            }
        },

        getMontirById(_, idMontir) {
            return new Promise((resolve, reject) => {
                api.get(`montir/${idMontir}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        async postMontir(_, data) {
            await api.post('montir', data)
        },

        async putMontir(_, data) {
            await api.put(`montir/${data.uid}`, data)
        }
    }
}