import { api } from "@/utils/api"

export default {
    namespaced: true,

    state: {
        fixedCost: [],
    },
    getters: {
        fixedCost(state) {
            return state.fixedCost
        }
    },
    mutations: {
        SET_FIXED_COST(state, fixedCost) {
            state.fixedCost = fixedCost
        }
    },
    actions: {
        async getFixedCost({ commit }) {
            try {
                await api.get('fixcost')
                    .then((response) => {
                        commit('SET_FIXED_COST', response.data.data)
                    })
                    .catch(() => {
                        commit('SET_FIXED_COST', [])
                    })
            } catch (e) {
                commit('SET_FIXED_COST', [])
            }
        },

        async postFixedCost(_, data) {
            await api.post('fixcost', data)
        },

        async putFixedCost(_, data) {
            await api.put(`fixcost/${data.uid}`, data)
        }
    }
}