import { api } from "@/utils/api";
import { resolveQueryParams } from "@/utils/helper";

export default {
    namespaced: true,

    state: {
        logistik: [],
        logistikUom: [],
    },
    getters: {
        logistik(state) {
            return state.logistik
        },
        logistikUom(state) {
            return state.logistikUom
        },
    },
    mutations: {
        SET_LOGISTIK(state, logistik) {
            state.logistik = logistik
        },
        SET_LOGISTIK_UOM(state, logistikUom) {
            state.logistikUom = logistikUom
        },
    },
    actions: {
        async getLogistikData({ commit }, query) {
            try {
                await api.get(`barang?${resolveQueryParams(query)}`, {
                    headers: {
                        "Content-Type": "application/json",
                    }
                })
                    .then((response) => {
                        commit('SET_LOGISTIK', response.data.data)
                    })
                    .catch(() => {
                        commit('SET_LOGISTIK', [])
                    })
            } catch (e) {
                commit('SET_LOGISTIK', [])
            }
        },
        async getLogistikDataWithUom({ commit }, query) {
            try {
                await api.get(`barang/stock?${resolveQueryParams(query)}`, {
                    headers: {
                        "Content-Type": "application/json",
                    }
                })
                    .then((response) => {
                        commit('SET_LOGISTIK_UOM', response.data.dataBarang)
                    })
                    .catch(() => {
                        commit('SET_LOGISTIK_UOM', [])
                    })
            } catch (e) {
                commit('SET_LOGISTIK_UOM', [])
            }
        },
        getLogistikByIdWithStock(_, query) {
            return new Promise((resolve, reject) => {
                api.get(`barang/stock?${resolveQueryParams(query)}`)
                    .then((response) => {
                        resolve(response.data.dataBarang[0])
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        getLogistikById(_, id) {
            return new Promise((resolve, reject) => {
                api.get('barang/' + id)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        postDataLogistik(_, data) {
            return new Promise((resolve, reject) => {
                api.post('barang', data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        postDataUomLogistik(_, { uid_barang, data }) {
            return new Promise((resolve, reject) => {
                api.post(`konversi?uid_barang=${uid_barang}`, data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        putDataLogistik(_, { uid_barang, data }) {
            return new Promise((resolve, reject) => {
                api.put(`barang/${uid_barang}`, data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        putDataUomLogistik(_, { uid_barang, data }) {
            return new Promise((resolve, reject) => {
                api.put(`konversi/${uid_barang}`, data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}