import { api } from "@/utils/api";
import { resolveQueryParams } from "@/utils/helper";

export default {
    namespaced: true,

    state: {
        listPr: [],
        listPo: [],
    },
    getters: {
        listPr(state) {
            return state.listPr
        },
        listPo(state) {
            return state.listPo
        }
    },
    mutations: {
        SET_LIST_PR(state, listPr) {
            state.listPr = listPr
        },
        SET_LIST_PO(state, listPo) {
            state.listPo = listPo
        }
    },
    actions: {
        async getListPr({ commit }, query) {
            try {
                await api.get(`pr?${resolveQueryParams(query)}`, {
                    headers: {
                        "Content-Type": "application/json",
                    }
                })
                    .then((response) => {
                        commit('SET_LIST_PR', response.data.data)
                    })
                    .catch(() => {
                        commit('SET_LIST_PR', [])
                    })
            } catch (e) {
                commit('SET_LIST_PR', [])
            }
        },
        async getListPo({ commit }, query) {
            try {
                await api.get(`po?${resolveQueryParams(query)}`)
                    .then((response) => {
                        commit('SET_LIST_PO', response.data.data)
                    })
                    .catch(() => {
                        commit('SET_LIST_PO', [])
                    })
            } catch (e) {
                commit('SET_LIST_PO', [])
            }
        },

        postPurchaseRequest(_, data) {
            return new Promise((resolve, reject) => {
                api.post('pr', data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        postPurchaseOrder(_, data) {
            return new Promise((resolve, reject) => {
                api.post('po', data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        putPurchaseRequest(_, { idPr, data }) {
            return new Promise((resolve, reject) => {
                api.put(`pr/${idPr}`, data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        putPurchaseOrder(_, { idPo, data }) {
            return new Promise((resolve, reject) => {
                api.put(`po/${idPo}`, data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        deletePurchaseRequest(_, idPr) {
            return new Promise((resolve, reject) => {
                api.delete(`pr/${idPr}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        deleteBarangPurchaseRequest(_, idBarangPr) {
            return new Promise((resolve, reject) => {
                api.delete(`pr/barang/${idBarangPr}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
    }
}