import { api } from "@/utils/api"
import { resolveQueryParams } from "@/utils/helper"

export default {
    namespaced: true,

    state: {
        listFabrikasi: [],
    },
    getters: {
        listFabrikasi(state) {
            return state.listFabrikasi
        }
    },
    mutations: {
        SET_LIST_FABRIKASI(state, listFabrikasi) {
            state.listFabrikasi = listFabrikasi
        }
    },
    actions: {
        async getListFabrikasi({ commit }, query) {
            try {
                await api.get(`fabrikasi?${resolveQueryParams(query)}`, {
                    headers: {
                        "Content-Type": "application/json",
                    }
                })
                    .then((response) => {
                        commit('SET_LIST_FABRIKASI', response.data.data)
                    })
                    .catch(() => {
                        commit('SET_LIST_FABRIKASI', [])
                    })
            } catch (e) {
                commit('SET_LIST_FABRIKASI', [])
            }
        },

        getDetailFabrikasi(_, uid) {
            return new Promise((resolve, reject) => {
                api.get(`fabrikasi/${uid}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        postFabrikasi(_, data) {
            return new Promise((resolve, reject) => {
                api.post('fabrikasi', data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        putFabrikasi(_, { idFabrikasi, data }) {
            return new Promise((resolve, reject) => {
                api.put(`fabrikasi/${idFabrikasi}`, data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        }
    }
}