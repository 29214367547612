import axios from "axios"

export default {
    namespaced: true,

    actions: {
        logIn(_, payload) {
            return new Promise((resolve, reject) => {
               axios.post(`${process.env.VUE_APP_ROOT_API}/login`, payload)
                    .then((response) => {
                        localStorage.setItem("DataAuth", JSON.stringify(response.data.data))
                        resolve(response.data.data)
                        //return dispatch('attempt', response.data.data.uid)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
    }
}