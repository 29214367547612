import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue')
  },
  //MASTER DATA
  {
    path: '/company_profile',
    name: 'company_profile',
    component: () => import('@/views/MasterData/CompanyProfile.vue')
  },
  {
    path: '/master_data_user',
    name: 'master_data_user',
    component: () => import('@/views/MasterData/User.vue')
  },
  {
    path: '/master_data_montir',
    name: 'master_data_montir',
    component: () => import('@/views/MasterData/Montir.vue')
  },
  {
    path: '/master_data_customer',
    name: 'master_data_customer',
    component: () => import('@/views/MasterData/Customer.vue')
  },
  {
    path: '/master_data_supplier',
    name: 'master_data_supplier',
    component: () => import('@/views/MasterData/Supplier.vue')
  },
  {
    path: '/master_data_uom',
    name: 'master_data_uom',
    component: () => import('@/views/MasterData/Uom.vue')
  },
  {
    path: '/master_data_fixed_cost',
    name: 'master_data_fixed_cost',
    component: () => import('@/views/MasterData/FixedCost.vue')
  },
  // LOGISTIK
  {
    path: '/data_logistik',
    name: 'data_logistik',
    component: () => import('@/views/Logistik/DataLogistik.vue')
  },
  {
    path: '/pr_logistik',
    name: 'pr_logistik',
    component: () => import('@/views/Logistik/PurchaseRequest.vue')
  },
  {
    path: '/pengambilan_logistik',
    component: () => import('@/views/Logistik/PengambilanBarang.vue'),
    children: [
      {
        path: '',
        name: 'data_service_logistik',
        component: () => import('@/views/Logistik/ListServiceLogistik.vue')
      },
      {
        path: '/pengambilan_logistik/proses_pengambilan_logistik',
        name: 'proses_pengambilan_logistik',
        props: true,
        component: () => import('@/views/Logistik/ProsesPengambilanBarang.vue')
      }
    ]
  },
  {
    path: '/fabrikasi',
    name: 'fabrikasi',
    component: () => import('@/views/Logistik/Fabrikasi.vue')
  },
  {
    path: '/pengambilan_fabrikasi',
    component: () => import('@/views/Logistik/PengambilanFabrikasi.vue'),
    children: [
      {
        path: '',
        name: 'data_fabrikasi_logistik',
        component: () => import('@/views/Logistik/ListFabrikasiLogistik.vue')
      },
      {
        path: '/pengambilan_fabrikasi/proses_pengambilan_logistik',
        name: 'proses_pengambilan_logistik_fabrikasi',
        props: true,
        component: () => import('@/views/Logistik/ProsesPengambilanFabrikasi.vue')
      }
    ]
  },
  {
    path: '/stock_opname',
    name: 'stock_opname',
    component: () => import('@/views/Logistik/StockOpname.vue')
  },
  // PURCHASING
  {
    path: '/create_po',
    component: () => import('@/views/Purchasing/CreatePurchaseOrder.vue'),
    children: [
      {
        path: '',
        name: 'data_list_pr',
        component: () => import('@/views/Purchasing/ListPurchaseRequest.vue'),
      },
      {
        path: '/create_po/detail_choosen_po',
        name: 'detail_choosen_po',
        props: true,
        component: () => import('@/views/Purchasing/DetailChoosen.vue')
      },
      {
        path: '/create_po/generate_po',
        name: 'generate_po',
        props: true,
        component: () => import('@/views/Purchasing/GeneratePo.vue')
      }
    ]
  },
  {
    path: '/list_po',
    name: 'list_po',
    component: () => import('@/views/Purchasing/ListPurchaseOrder.vue')
  },
  // SERVICE
  {
    path: '/service',
    component: () => import('@/views/Service/Service.vue'),
    children: []
  },
  //BENGKEL
  {
    path: '/bengkel',
    component: () => import('@/views/Bengkel/Bengkel.vue'),
    children: [
      {
        path: '',
        name: 'data_service_bengkel',
        component: () => import('@/views/Bengkel/ListServiceBengkel.vue')
      },
      {
        path: '/bengkel/proses_service',
        name: 'proses_service',
        props: true,
        component: () => import('@/views/Bengkel/ProsesService.vue')
      }
    ]
  },
  //LAPORAN
  {
    path: '/laporan_per_service',
    name: 'laporanPerService',
    component: () => import('@/views/Laporan/LaporanPerService.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
