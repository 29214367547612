import Vue from 'vue'
import Vuex from 'vuex'
import login from './module/login'
import auth from './module/auth'
import companyProfile from '@/store/module/CompanyProfile'
import user from '@/store/module/user'
import montir from '@/store/module/montir'
import customer from '@/store/module/customer'
import supplier from '@/store/module/supplier'
import uom from '@/store/module/uom'
import fixedCost from './module/fixedCost'
import logistik from './module/logistik'
import purchase from './module/purchase'
import service from './module/service'
import fabrikasi from './module/fabrikasi'
import spk from './module/spk'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    login,
    auth,
    companyProfile,
    user,
    montir,
    customer,
    supplier,
    uom,
    fixedCost,
    logistik,
    purchase,
    service,
    fabrikasi,
    spk
  }
})
