import { api } from "@/utils/api";

export default {
    namespaced: true,

    state: {
        loginApiStatus: null,
        profile: null,
    },

    getters: {
        getLoginApiStatus(state) {
            return state.loginApiStatus;
        },
        profile(state) {
            return state.profile
        },
    },

    mutations: {
        setLoginApiStatus(state, data) {
            state.loginApiStatus = data;
        },
        SET_PROFILE(state, data) {
            state.profile = data
        },
    },

    actions: {
        async logIn({ dispatch }, payload) {
            const response = await api.post('login', payload)
            let token = response.data.data.token;
            let user = response.data.data.uid;
            localStorage.setItem('xrfgthj', user)
            return dispatch('attempt', token)
        },

        async attempt({ commit, state }, token) {
            if (token) {
                commit("setLoginApiStatus", token);
                await api.get(`users/${localStorage.getItem('xrfgthj')}`)
                    .then((response) => {
                        commit('SET_PROFILE', response.data)
                    })
                    .catch(() => {
                        commit('SET_PROFILE', [])
                    })
            }

            if (!state.token) {
                return
            }
        },

        async logOut({ commit }) {
            await api.delete('logout').then(() => {
                commit('SET_PROFILE', null)
                localStorage.removeItem("xrfgthj")
                localStorage.removeItem("token")
            })
        }
    },
}