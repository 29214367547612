import router from '@/router';
import axios from 'axios';

let state = false
const api = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API,
    mode: "cors",
    withCredentials: true,
    credentials: 'include',
    headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "https://djhapi.ipnusantara.com",
        //"Access-Control-Allow-Origin": "http://202.157.186.101:6868",
    },
}, {
    withCredentials: true,
})

api.interceptors.response.use(
    resp => resp, async error => {
        if (error.response.status == 401 && !state) {
            state = true
            localStorage.removeItem('token')
            router.replace('/')
        }
        state = false
        return Promise.reject(error)
    }
)

export { api };