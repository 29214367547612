import { api } from "@/utils/api"

export default {
    namespaced: true,

    state: {
        uom: [],
        uomKonv: [],
    },
    getters: {
        uom(state) {
            return state.uom
        },
        uomKonv(state) {
            return state.uomKonv
        }
    },
    mutations: {
        SET_UOM(state, uom) {
            state.uom = uom
        },
        SET_UOM_KONVERSI(state, uomKonv) {
            state.uomKonv = uomKonv
        },
    },
    actions: {
        async getUom({ commit }) {
            try {
                await api.get('satuan')
                    .then((response) => {
                        commit('SET_UOM', response.data.data)
                    })
                    .catch(() => {
                        commit('SET_UOM', [])
                    })
            } catch (e) {
                commit('SET_UOM', [])
            }
        },
        async getUomKonversi({ commit }) {
            try {
                await api.get('konversi')
                    .then((response) => {
                        commit('SET_UOM_KONVERSI', response.data.data)
                    })
                    .catch(() => {
                        commit('SET_UOM_KONVERSI', [])
                    })
            } catch (e) {
                commit('SET_UOM_KONVERSI', [])
            }
        },

        getUomById(_, idUom) {
            return new Promise((resolve, reject) => {
                api.get(`satuan/${idUom}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        getUomKonversiById(_, idUom) {
            return new Promise((resolve, reject) => {
                api.get(`konversi/${idUom}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        postUom(_, data) {
            return new Promise((resolve, reject) => {
                api.post('satuan', data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },

        putUom(_, data) {
            return new Promise((resolve, reject) => {
                api.put(`satuan/${data.uid}`, data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
}