import { api } from "@/utils/api"

export default {
    namespaced: true,

    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        postSpk(_, data) {
            return new Promise((resolve, reject) => {
                api.post('spk', data)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        deletePengerjaan(_, uid) {
            return new Promise((resolve, reject) => {
                api.delete(`spk/pengerjaan/${uid}`)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    }
}