<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style scoped>
/* ::v-deep .v-data-table-header {
  background-color: #1D460F;
} */
::v-deep .custom_table_class thead th {
  background-color: #8BC34A;
}
::v-deep .custom_table_class thead th:first-child {
  border-radius: 6px 0 0 0;
}
::v-deep .custom_table_class thead th:last-child {
  border-radius: 0 6px 0 0;
}

::v-deep .add_table {
  width: 100%;
  height: 100%;
}
::v-deep .add_table thead th {
  padding: 5px;
  background-color: #ffc0cb;
  color: black;
}
::v-deep .add_table thead th:first-child {
  border-radius: 6px 0 0 0;
}
::v-deep .add_table thead th:last-child {
  border-radius: 0 6px 0 0;
}

::v-deep .border_dashed {
  border: 1pt dashed #CECECE;
  border-radius: 10px;
}

::v-deep .v-input__slot {
  min-height: 10px !important;
}
</style>
